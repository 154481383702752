import React from 'react';

function ProjectsPage() {
  return (
    <div>
      <main className="Projects-main">
      <svg className="fade-in" width="100%" height="300" viewBox="0 0 500 150" xmlns="http://www.w3.org/2000/svg">
        {/* Adjusted the path for a wider arc and larger viewBox */}
        <path id="curvePath" fill="transparent" d="M10,150 Q250,0 490,150" stroke="red" strokeWidth="0" />
        <text fill="#f1deaa">
          <textPath href="#curvePath" startOffset="50%" textAnchor="middle" style={{ fontSize: '35px' }}>
            COMING SOON
          </textPath>
        </text>        
      </svg>
      </main>
    </div>
  );
}

export default ProjectsPage;
