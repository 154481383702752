import React from 'react';
import './ContactPage.css'; // Import the new CSS file

function ContactPage() {
  return (
    <div className="ContactPage"> {/* Add a class for styling */}
      <p className="fade-in">baasil.ali@gmail.com</p> {/* Add the fade-in class */}
      <p className="fade-in">baasil.ali@sjsu.edu</p> {/* Add the fade-in class */}
    </div>
  );
}

export default ContactPage;
