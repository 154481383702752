import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';

// Import your page components
import HomePage from './components/HomePage'; // This is your new homepage component
import AboutPage from './components/AboutPage';
import ProjectsPage from './components/ProjectsPage';
import ContactPage from './components/ContactPage';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <nav>
            <Link to="/">HOME</Link>
            <Link to="/about">ABOUT</Link>
            <Link to="/projects">PROJECTS</Link>
            <Link to="/contact">CONTACT</Link>
          </nav>
        </header>

        {/* Set up the routes */}
        <Routes>
          <Route path="/" element={<HomePage />} /> {/* Render HomePage component here */}
          <Route path="/about" element={<AboutPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
        <footer className="App-footer">
          <a href="https://www.instagram.com/baasilali/" target="_blank" rel="noopener noreferrer">
            {/* Replace these placeholders with actual icons */}
            <img src="/icons/instagram.png" alt="Instagram" />
          </a>
          <a href="https://www.linkedin.com/in/baasilali/" target="_blank" rel="noopener noreferrer">
            <img src="/icons/linkedin.png" alt="LinkedIn" />
          </a>
          <a href="https://github.com" target="_blank" rel="noopener noreferrer">
            <img src="/icons/github.png" alt="GitHub" />
          </a>
        </footer>
      </div>
    </Router>
  );
}

export default App;
