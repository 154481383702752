import React from 'react';
import './AboutPage.css'; // Make sure to create an AboutPage.css file for styling

function AboutPage() {
  return (
    <div className="AboutPage">
      <div className="AboutLeft">
        <h2>Computer Science / Buissness Economics @ SJSU</h2>
        <ul>
          <li>2021-2025</li>
          <li>2x Hackathon Winner</li>
          <li>1x Dean's Scholar</li>
          {/* Add more list items for other accomplishments */}
        </ul>

        <p>Career and Academia aside I'd describe myself as ambitious and forthcoming.
            Our world has been defined by contrarians with innovative ideas or contrarians
            with strong fundamental principles. I applaud their bravery and acknowledge their egos.
        </p>
        <p> In terms of my work, I hope to be a benefit to my peers and work environment
            by continuing to debate and challenge consensuses which catalyze growth
            in my communities.
        </p>
        <p> To speak a bit about my intrests, I've always been an artist through inclination. Through poets like Rumi,
            film-makers like Shinichirō Watanabe, authors like Takehiko Inoue, musicians like Sade, through nature, and even
            as an engineer. Creativity is the best form of self-expression. It's what makes us unique, and it is what helps us leave
            a benifical mark on our corners of the world. 
        </p>
        <h2>"How much does one know, when one knows that "red" applies to red things" -Tyler Burge (Relfections on Chomsky)</h2>

      </div>
      <div className="AboutRight">
        <img src="/images/martins.png" alt="Interest 1" />
        <img src="/images/me.png" alt="Interest 2" />
        <img src="/images/tahoe.png" alt="Interest 3" />
        {/* Add more images if needed */}
      </div>
    </div>
  );
}

export default AboutPage;
